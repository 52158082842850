//#region react import
import React, { useState } from "react";
import { useSelector } from "react-redux";
//#endregion

//#endregion component component
import ImportStudentsModal from "../../Components/ImportStudentsModal/ImportStudentsModal.component";
import PageWithTitle from "../../Components/PageWithTitle/PageWithTitle.component";
import CreateStudent from "../../Pages/Student/CreateStudent/CreateStudent.component";
import GlobalLayout from "../GlobalLayout/GlobalLayout.component";
//#endregion

//#region functions import
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region constants import
import { STUDENTS_CREATE } from "../../Routing/PageNames";
import { GROUPS, ICON_UPLOAD_FILE } from "../../Constants/IconConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region style import
import "./CreateStudentLayout.style.scss";
//#endregion

const CreateStudentLayout = () => {
  const [importModalIsOpen, setImportModalIsOpen] = useState(false);
  const translations = useSelector((state) => state.translationSlice.translations);

  const renderPageHeader = () => {
    return (
      <div className="create-student_title">
        <h1>{getTranslations(TRANSLATIONS.STUDENT_CREATE_STUDENT_ADD_STUDENT, translations)}</h1>
        <button
          className="gsta-button-outlined"
          onClick={() => {
            setImportModalIsOpen(true);
          }}
        >
          <i className={ICON_UPLOAD_FILE}/>
          {getTranslations(TRANSLATIONS.EXCEL_IMPORT_BUTTON_IMPORT, translations)}
        </button>
      </div>
    );
  };

  return (
    <GlobalLayout
      icon={GROUPS}
      title={STUDENTS_CREATE.pageTraductionKey}
    >
      <PageWithTitle header={renderPageHeader()}>
        {importModalIsOpen && (
          <ImportStudentsModal
            modalOpen={importModalIsOpen}
            setModalOpen={setImportModalIsOpen}
          />
        )}
        <CreateStudent />
      </PageWithTitle>
    </GlobalLayout>
  );
};

export default CreateStudentLayout;
