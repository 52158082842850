//#region react import
import { useSelector } from "react-redux";
//#endregion

//#region functions import
import { stringFormatterOverload } from "../../Helper/StringFormatter";
import { getTranslations } from "../../Helper/TranslationController";
//#endregion

//#region components import
import { AcreosSpan } from "acreos-ui";
//#endregion

//#region services import
//#endregion

//#region store import
//#endregion

//#region constants import
import { ICON_GROUPS } from "../../Constants/IconConstants";
import { TRANSLATIONS } from "../../Constants/Translations";
//#endregion

//#region style import
import "./SessionToolTipContent.style.scss";
//#endregion

/**
 *
 * description
 *
 */

const SessionToolTipContent = ({ sessionNumber, studentConnectedNumber, spaceAvailableNumber, students }) => {
  //#region state
  //#endregion

  //#region constants
  //#endregion

  //#region others use...
  const translations = useSelector((state) => state.translationSlice.translations);
  //#endregion

  //#region functions
  const renderContent = () => {
    return students.map((student, index) => (
      <AcreosSpan
        key={`${student.machineCode}-${index}`}
        appearanceClass={"outlined"}
      >
        {stringFormatterOverload(student.firstName.concat(student.lastName), 14)}{" "}
        <i className={`icon-${student.machineCode}`} />
      </AcreosSpan>
    ));
  };
  //#endregion

  //#region useQuery
  //#endregion

  //#region useEffect
  //#endregion

  return (
    <div className="session-tool-tip-content">
      <header>
        <span>{`${getTranslations(TRANSLATIONS.COLLABORATION_SESSION, translations)} ${sessionNumber}`}</span>
        <div>
          <i className={ICON_GROUPS} />
          <span>{`${studentConnectedNumber}/${spaceAvailableNumber} ${getTranslations(
            TRANSLATIONS.COLLABORATION_OBSERV_STUDENTS_CONNECT,
            translations
          )}`}</span>
        </div>
      </header>
      <article>{renderContent()}</article>
      <footer>
        <span>{getTranslations(TRANSLATIONS.COLLABORATION_OBSERV_CLICK_INFO, translations)}</span>
      </footer>
    </div>
  );
};
export default SessionToolTipContent;
